

















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Button from '../Button/index.vue'
import SuccessIcon from '@/app/ui/assets/checklist_circle_icon.vue'
import CloseCircle from '@/app/ui/assets/close_circle.vue'
import CloseLine from '@/app/ui/assets/close_line.vue'
import IllustrationCheck from '@/app/ui/assets/illustration_check.vue'
import IllustrationFailed from '@/app/ui/assets/illustration_failed_sent.vue'

@Component({
  components: {
    Button,
    SuccessIcon,
    CloseCircle,
    CloseLine,
    IllustrationCheck,
  },
})
export default class Modal extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop() private title!: string
  @Prop() private body!: string
  @Prop({ default: 'modal-container' }) private modalContainer!: string
  @Prop({ default: 'none' }) private type!:
    | 'success'
    | 'warning'
    | 'failure'
    | 'none'
    | 'cancel'
  @Prop({ default: false }) private showCloseIcon!: boolean
  @Prop({ default: true }) private center!: boolean
  @Prop({ default: 'Okay' }) private textButton!: string

  @Watch('visible')
  onVisibleChanged(val: boolean): void {
    this.isVisible = val
  }

  isVisible = this.visible

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private hideModal() {
    this.isVisible = false
    this.$emit('close')
    this.$emit('change', { modalVisibility: this.isVisible })
  }
}
